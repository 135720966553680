<script>
import urlLinks from '../../plugins/linkUrl';

export default {
  name: 'PFGwSModalComponent',
  data: () => ({
    learnMore: urlLinks.HELP_TEXT_LEARN_MORE.GROW_WITH_SAP,
    maxWidth: 694,
    isOverflownFlag: false,
  }),
  props: {
    growCountries: { type: Array, default: () => [] },
    closePopup: { type: Function, default: () => {} },
    groupedAccount: { type: Boolean, default: false },
    showCountryList: { type: Boolean, default: true },
  },
  methods: {
    countryItem(idx, growCountries, country) {
      if (growCountries.length === 1) {
        return `${country}.`;
      }
      if (idx === growCountries.length - 1) {
        return `${this.$t('and')} ${country}.`;
      }
      if (idx === growCountries.length - 2) {
        return `${country} `;
      }
      return `${country}, `;
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalScrollContent.scrollHeight
          > this.$refs.modalScrollContent.clientHeight
        ) {
          this.isOverflownFlag = true;
          this.$refs.growContainer.style.paddingRight = '32px';
          this.$refs.modalScrollContent.style.marginRight = '8px';
          this.$refs.modalScrollContent.style.marginTop = '20px';
          this.$refs.modalScrollContent.style.marginBottom = '20px';
        } else {
          this.isOverflownFlag = false;
          this.$refs.growContainer.style.paddingRight = '0px';
          this.$refs.modalScrollContent.style.marginRight = '32px';
        }
      });
    },
  },
  computed: {
    maxWidthStyle() {
      return `${this.maxWidth}px`;
    },
    countriesListString() {
      const sortedCountries = [...this.growCountries].sort((a, b) => a.localeCompare(b));
      return sortedCountries.map((c, i) => this.countryItem(i, sortedCountries, c)).join('');
    },
  },
  mounted() {
    this.isOverflown();
  },
};
</script>

<template>
  <div class="ds-modal ds-modal--active ds-modal--scrollable"
        ref="platinumEditModal" tabindex="0" @keyup.esc="closePopup">
    <div class="modal-container">
      <div class="modal-container-inner">
        <div class="ds-modal__box GwS-logo-modal">
          <div class="ds-modal__box__header">
            <div class="ds-modal__box__header__heading">
              {{$t('grow.title')}}
              <i
                class="ds-icon ds-icon--close modal-close-icon"
                @click="closePopup"
              ></i>
            </div>
          </div>
          <div class="ds-modal__box__content" ref="modalScrollContent">
            <div class="solution-container">
              <div class="grow-container" ref="growContainer">
                <div class="solution-section-title">
                  <img class="GwS-logo" src="@/assets/GwS_logo.png">
                </div>
                <p class="grow-p">
                  {{$t('grow.p1')}}
                  <span @click="openLearnMore" class="help-text-link">
                    <a :href="learnMore" target="_blank" rel="noopener noreferrer">{{$t('learn.more')}}</a>
                  </span>
                </p>
                <!-- only show country list if profile is group profile -->
                <p v-if="groupedAccount && showCountryList" class="grow-p" id="grow-p2">
                  <span ref="countriesLabel">{{ $tc('grow.p2', growCountries.length) }}&nbsp;</span>
                  <span class="country-names inline-word-text">
                    {{ countriesListString }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="ds-modal__box__action-bar">
            <button
              class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-x-none"
              type="button"
              @click="closePopup"
              id="platinum-logo-button__done"
            >
              {{ $t("button.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

.ds-modal--active {
    z-index: 200;
}
.border-none {
  border: none !important;
}
.modal-container-inner {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.GwS-logo-modal {
  border-color: @color-grey-5;
  border-width: '1px';
  max-width: 564px;
  position: relative;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .ds-modal__box__content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .ds-modal__box__action-bar {
    padding-top: 20px;
  }

  .ds-modal__box__header {
    padding-bottom: 20px;
  }
  .ds-modal__box__header__heading {
    color: @color-grey-0;
    &:extend(.font-normal);
    font-size: 27px;
    line-height: 35px;
  }
  .ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
  }
  .ds-modal__box__content::-webkit-scrollbar-thumb {
    background-color: @color-gray-4;
    border-radius: 20px;
  }
  .modal-close-icon {
      width: 32px;
      height: 32px;
      float: right;
      margin-right: 33px;
      color: @color-gray-2;
      position: absolute;
      top: 20px;
      right: 0;
      cursor: pointer;
    }
    .modal-close-icon::before {
      font-size: 23px;
    }

    .modal-close-icon:hover {
      color: @color-glance-consultants;
    }
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grow-container {
  max-width: v-bind('maxWidthStyle');
}

.grow-p {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 0px;

}

.country-names {
  &:extend(.font-expanded);
  font-size: 16px;
  line-height: 24px;
}

.inline-word-text {
  display: inline;
}

.solution-section-title {
  margin-bottom: 20px;
  .GwS-logo {
    height: 54px;
  }
  text-align: center;
}

</style>
