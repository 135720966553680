<script>
const MAX_ITEM_DEFAULT_SHOW = 7;

export default {
  name: 'PFItemListComponent',
  data: () => ({
    showAllToggle: false,
    tooltipOpen: false,
  }),
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    moreTitle: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    itemKey: {
      type: String,
      required: true,
    },
    itemTextKey: {
      type: String,
      required: true,
    },
    descriptionTextKey: {
      type: String,
      required: false,
    },
    initialDisplayCount: {
      type: Number,
      default: () => MAX_ITEM_DEFAULT_SHOW,
    },
    helpText: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    locationSection: {
      type: Boolean,
      default: false,
    },
    helpTextLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    openLearnMore() {
      const win = window.open(this.helpTextLink, '_blank');
      win.focus();
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
  },
  computed: {
    getItems() {
      if (!this.showAllToggle && this.items && this.items.length > 0 && !this.editMode) {
        return this.items.slice(0, this.initialDisplayCount);
      } if (!this.items) {
        return [];
      }
      return this.items;
    },
    showMoreItems() {
      return this.items
        && this.items.length > this.initialDisplayCount
        && this.items.length - this.initialDisplayCount > 0;
    },
    moreItems() {
      if (this.items && this.items.length > this.initialDisplayCount) {
        return Math.abs(this.items.length - this.initialDisplayCount);
      }
      return '';
    },
  },
};
</script>

<template>
<div class="item-list-container"
     :class="locationSection ? 'col-content-min-width item-list-line-height-local' : 'item-list-line-height-global'">
  <div class="list-heading-container">
    <h3
      :class="[
        (editMode ? 'edit-text-color' : 'view-text-color'),
        (locationSection ? 'ds-m-b-xs location-header-size' : 'ds-m-b-s service-header-size')
      ]"
      class="ds-heading--s ds-m-t-s default-cursor">
      {{ listTitle }}
      <div  v-if="helpText.length > 0" class="tool-tip-container" v-click-outside="toggleTooltip">
              <i class="ds-icon ds-icon--info tool-tip-icon ds-m-r-xs"
            :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
            <div class='ds-tooltip-icon toolitp-icon-item-list'>
                <div
                  :class="[tooltipOpen ? 'display-block' :
                  'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
                   {{ helpText }}
                    <span v-if="!editMode" @click="openLearnMore" class="help-text-link">
                      <b> {{$t('learn.more')}}</b>
                    </span>
                </div>
              </div>
      </div>
    </h3>
  </div>
  <ul v-if="getItems" class="ds-list item-list ds-m-none"
      :class="locationSection ? 'location-item-size' : 'service-item-size'">
    <li
      class="ds-list__item default-cursor"
      :class="(descriptionTextKey && !locationSection) ? 'ds-m-b-s' : 'ds-m-b-xs'"
      :id="item[itemKey]"
      :key="item[itemKey]"
      v-for="item in getItems">
      <span class="item-list--text" :class="editMode ? 'edit-text-color' : 'view-text-color'">
        {{ item[itemTextKey] }}
      </span>
      <span v-if="descriptionTextKey" :class="[
              editMode ? 'edit-text-color' : 'view-text-color',
              locationSection ? 'item-list--description-location' : 'item-list--description-service'
            ]">
        {{ item[descriptionTextKey] }}
      </span>
    </li>
    <li class="ds-m-b-xs"
      v-if="showMoreItems && !editMode"
      v-show="!showAllToggle">
      <a
        href=""
        class="show-more-link"
        @click.prevent="showAllToggle = !showAllToggle">
        {{ moreItems }} more {{ moreTitle || listTitle }}
      </a>
    </li>

  </ul>
  <ul v-if="getItems.length === 0" class="ds-list item-list empty-item-list ds-m-none">
    <li class="ds-m-b-xs" :class="editMode ? 'edit-text-color' : ''">
      &mdash;
    </li>
  </ul>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.item-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  & li {
    padding-left: 10px;
    text-indent: -10px;
    text-align: left;
    &:extend(.font-normal);

    span {
      &.item-list--text {
        text-align: left;
      }

      .item-list-description (@lineHeight, @fontSize) {
        &:extend(.benton-sans-light);
        display: block;
        font-size: @fontSize;
        text-indent: 0 !important;
        margin-left: -10px;
        line-height: @lineHeight;
      }

      &.item-list--description-service {
        &:extend(.benton-sans-light);
        .item-list-description(17px, 14px);
      }

      &.item-list--description-location {
        &:extend(.benton-sans-light);
        .item-list-description(18px, 12px);
      }
    }
  }
}
.item-list-container {
  position: relative;
  .tool-tip-container {
    display: inline-block;
    height: 27px;
    position: relative;
    .tool-tip-icon::before {
      font-size: 18px !important;
      line-height: 27px !important;
      cursor: pointer;
    }
    .tool-tip-icon {
      color: @color-gray-4;
    }
    .tool-tip-icon:hover {
      color: @color-gray-3;
    }
  }
  .show-more-link {
    color: @color-link;

    &:hover {
      color: @color-link__hover;
    }
  }
}
.ds-tooltip-icon {
  position: absolute !important; //ordering issues with ds.css loading after and overriding our override
  z-index: 3;
}
.edit-text-color {
  color: @color-icon-before;
}

// h3.edit-text-color:hover {
//   color: @color-gray-3;
// }

.toolitp-icon-item-list {
  left:100%;
  bottom: calc(100% - 20px);
}

.list-heading-container {
  width: fit-content;
  position: relative;
}

.location-header-size {
  font-size: 16px;
  &:extend(.font-expanded);
}

.location-item-size {
  font-size: 14px;
}

.service-header-size {
  font-size: 21px;
  &:extend(.font-expanded);
}

.service-item-size {
  font-size: 16px;
}
.view-text-color {
  color: @color-black;
}
</style>
