<script>
import { mapState } from 'vuex';
import PFSearchBarComponent from '@/components/common/PFSearchBarComponent/PFSearchBarComponent';
import PFSiteLogoComponent from '@/components/common/PFSiteLogoComponent';
import PFHelpComponent from '@/components/common/PFHelpComponent';
import PFUserProfileComponent from '@/components/common/PFUserProfileComponent';
import PFDisclaimerComponent from '@/components/common/PFDisclaimerComponent';
import PFMobileFilterComponent from '@/components/common/PFMobileFilterComponent';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFHeaderComponent',
  props: {
    updateSearchQuery: { type: Function, default: (value) => value },
    hasTheSearchField: { type: Boolean, default: true },
    title: { type: String },
  },
  components: {
    PFSearchBarComponent,
    PFSiteLogoComponent,
    PFHelpComponent,
    PFUserProfileComponent,
    PFDisclaimerComponent,
    PFMobileFilterComponent,
  },
  data: () => ({
    windowWidth: 0,
    removeSearchBar: true,
  }),
  mounted() {
    if (this.$router.currentRoute.value.name !== IE_BROWSER_PAGE) {
      this.removeSearchBar = false;
    }
    this.$store.dispatch('systemMaintenance/fetchActiveMaintenanceData');
  },
  computed: {
    ...mapState('systemMaintenance', {
      enableMaintenanceBanner: (state) => state.enableMaintenanceBanner,
    }),
  },
  methods: {
    showSearchBarInHeader() {
      return this.$route.path === '/' || this.$route.path.includes('editor');
    },
    isEditorPageHeader() {
      return this.$route.path.includes('editor') || this.$route.path.includes('partner-terms-of-use');
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1199) {
        this.$store.commit('solutionL2Store/setMobileMode', true);
      } else {
        this.$store.commit('solutionL2Store/setMobileMode', false);
      }
    },
    closeDisclaimer() {
      this.$store.commit('systemMaintenance/setEnableMaintenanceBanner', false);
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<template>
  <div class="pf-page-header" id ="pf-page-header">
    <div class="pf-header-container">
      <div class="pf-page-header__logo">
        <PFSiteLogoComponent :title="title" :editorHeader="isEditorPageHeader()" :blackHeaderLogo="true"/>
      </div>
      <div v-if="!removeSearchBar && !showSearchBarInHeader()" class="pf-page-header__search">
        <PFSearchBarComponent :updateSearchQuery="updateSearchQuery" />
      </div>
      <div class="spacer"></div>
      <div class="pf-page-header__help-block" v-if="!removeSearchBar">
        <PFHelpComponent />
      </div>
      <div class="pf-page-header__profile-block" v-if="!removeSearchBar">
        <PFUserProfileComponent/>
      </div>
    </div>
    <div v-if="!removeSearchBar && !showSearchBarInHeader() && windowWidth <= 1199" class="pf-page-small-header__search">
        <PFSearchBarComponent :updateSearchQuery="updateSearchQuery"/>
        <PFMobileFilterComponent/>
    </div>
    <PFDisclaimerComponent v-show="enableMaintenanceBanner" :closeDisclaimer="closeDisclaimer"/>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.spacer {
  flex: 1;
}
.pf-page-header {
  background-color: @black-color;
  justify-content: center;
  width: 100%;
  height: 80px;
  height: fit-content;

  .pf-header-container {
    display: flex;
    width: 100%;
    max-width: 1784px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .pf-page-header__search {
    margin: 21px 0 19px 9.42%;
    width: 47.36%;
  }

  .pf-page-small-header__search {
    position:relative;
    display: none;
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
  }

  .pf-page-header__logo {
    margin: 0.8px 0 0.8px 52px;
  }

  .pf-page-header__help-block {
    display: flex;
  }

  .pf-page-header__profile-block {
    display: flex;
  }
}
</style>
