<script>
export default {
  name: 'PEPlatinumPopupComponent',
  props: {
    closePopup: {
      type: Function,
      default: () => {},
    },
    goldCountries: {
      type: Array,
      default: () => [],
    },
    showGoldLogo: {
      type: Boolean,
      default: false,
    },
    groupedAccount: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showAllCountires: false,
  }),
  computed: {
    showCountries() {
      if (this.goldCountries.length <= 5 || this.showAllCountires) {
        return this.goldCountries;
      }
      return this.goldCountries.slice(0, 4);
    },
    companyName() {
      return this.$store.state.partnerProfile.profileData.name;
    },
  },
  mounted() {
    window.setTimeout(() => this.$refs.platinumEditModal.focus(), 0);
  },
};
</script>

<template>
    <div>
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="platinumEditModal" tabindex="0" @keyup.esc="closePopup">
      <div class="modal-container">
        <div class="modal-container-inner-platinum">
      <div class="ds-modal__box platinum-logo-modal">
        <div class="ds-modal__box__header border-none">
          <div class="ds-modal__box__header__heading">
            {{$t('sap.partner.edge')}}
            <i
              class="ds-icon ds-icon--close modal-close-icon"
              @click="closePopup"
            ></i>
          </div>
        </div>
        <div class="ds-modal__box__content" :class="showGoldLogo ? 'gold-country-max-height' : ''">
          <div class="dialog-text">
            <div v-if="showGoldLogo">
              <div class="platinum-popup--container ds-m-b-s">
                <img class="platinum-popup--logo" src="@/assets/SAP_GoldPartner_grad_R.png">
              </div>
              <div class="platinum-logo--text ds-m-b-s"> {{$t('sap.gold.partner') }}</div>
              <div class="platinum-text"> {{$t('sap.gold.partner.text') }}</div>
              <div class="platinum-text ds-m-t-m" v-if="groupedAccount">
                {{goldCountries.length > 1 ? $t('sap.gold.partner.text.b', {company: this.companyName})
                  : $t('sap.gold.partner.text.b.single', {company: this.companyName})}}
              </div>
              <ul class="ds-list ds-m-none gold-country-list" v-if="groupedAccount">
                  <li class="ds-list__item ds-bullet__item gold-country-list-bullet"
                    v-for="country in showCountries" :key="country">
                    <span class="platinum-text">{{country}}</span>
                  </li>
              </ul>
              <div class="more-gold-countries"
                @click="showAllCountires = true"
                v-if="!(this.goldCountries.length <= 5 || this.showAllCountires)">
                {{goldCountries.length - 4}}
                {{ $t('sap.gold.partner.more.countries') }}
              </div>
            </div>
            <div v-else>
              <div class="platinum-popup--container ds-m-b-s">
                <img class="platinum-popup--logo" src="@/assets/SAP_PlatinumPartner_R.png">
              </div>
              <div class="platinum-logo--text ds-m-b-s"> {{$t('sap.platinum.partner') }}</div>
              <div class="platinum-text"> {{$t('sap.platinum.partner.text') }}</div>
            </div>
          </div>
        </div>
        <div class="ds-modal__box__action-bar border-none">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-x-none"
            type="button"
            @click="closePopup"
            id="platinum-logo-button__done"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.modal-container-inner-platinum {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.ds-modal--active {
    z-index: 200;
}
.platinum-logo-modal {
  max-width: 564px;
  position: relative;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .dialog-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
    max-width: none;
    width: fit-content !important;
  }
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
    font-size: 27px;
    line-height: 35px;
  }
  .modal-close-icon {
      width: 23px;
      height: 23px;
      float: right;
      margin-right: 33px;
      color: @color-gray-4;
      position: absolute;
      top: 20px;
      right: 0;
      cursor: pointer;
    }

    .modal-close-icon::before {
      font-size: 23px;
    }

    .modal-close-icon:hover {
      color: @color-glance-consultants;
    }
    .platinum-popup--container {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
    .platinum-popup--logo {
      width: 85.1px;
      height: 50px;
    }
    .platinum-logo--text {
      &:extend(.font-expanded);
      font-size: 21px;
      line-height: 27px;
      color: @color-grey-0;
      text-align: center;
    }
    .platinum-text {
      &:extend(.font-normal);
      font-size: 16px;
      line-height: 24px;
      color: @color-grey-0;
    }
    .gold-country-list {
      padding-inline-start: 28px;
    }
    .gold-country-max-height {
      max-height: 417px;
    }
    .border-none {
      border: none !important;
    }
    .more-gold-countries {
      padding-left: 28px;
      cursor: pointer;
      color: @color-link-show-more;
    }
    .more-gold-countries:hover {
      color: @color-link__hover;
    }
}
</style>
